.image-category:hover {
  background: #1594a2;
  cursor: pointer;
}
.image-category {
  width: 380px;
  height: 340px;
  padding: 10px;

  @media (min-width: 768px) {
    width: 240px;
    height: 220px;
  }
  @media (max-width: 1024px) {
    width: 370px;
    height: 320px;
  }
  @media (min-width: 1024px) {
    width: 380px;
    height: 340px;
  }
  @media (min-width: 1440px) {
    width: 480px;
    height: 430px;
  }
}
